<template>
  <div>
    <div class="page-title">原料出库日志</div>
    <!-- 选项 -->
    <div style="margin-top:1rem;float:left;width:100%;">
      <el-date-picker
        size="mini"
        v-model="form.日期"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-select
        clearable
        filterable
        placeholder="搜索项"
        v-model="form.选项"
        size="mini"
        style="width:120px"
      >
        <el-option
          v-for="item in ['原料id','品名','料号','供应商','规格','领用部门','领用人','操作人','备注']"
          :value="item"
        >{{ item }}
        </el-option>
      </el-select>
      <el-input
        clearable
        placeholder="请输入关键词"
        size="mini"
        style="width:200px"
        v-model="form.关键词"
        @keydown.native.enter="GetRawOutLogs"
      ></el-input>
      <el-button
        size="mini"
        plain
        type="success"
        style="margin-left:3px"
        @click="GetRawOutLogs"
      >搜索
      </el-button>
      <el-button
        size="mini"
        type="primary"
        plain
        @click="OutPut"
      >出库日志-导出
      </el-button>
    </div>
    <!-- 表格 -->
    <div style="margin-top:1rem;float:left;width:100%">
      <pl-table
        :data="tableData"
        height="500"
      >
        <pl-table-column
          label="出库日期"
          width="110"
        >
          <template slot-scope="scope">
            <span>{{ FormatDate(scope.row.日期) }}</span>
          </template>
        </pl-table-column>
        <pl-table-column
          v-for="item in ['原料id','分类','品名','料号','供应商']"
          :label="item"
          :prop="item"
          :width="GetTdWidth(item)"
        ></pl-table-column>
        <pl-table-column label="规格" width="150">
          <template slot-scope="scope">
            <span v-html="FormatGG(scope.row.规格)"></span>
          </template>
        </pl-table-column>
        <pl-table-column
          v-for="item in ['单位','数量','领用部门','领用人','操作人']"
          :label="item"
          :prop="item"
          :width="GetTdWidth(item)"
        ></pl-table-column>
        <pl-table-column label="操作时间" width="180">
          <template slot-scope="s">
            <span>{{ FormatTime(s.row.操作时间) }}</span>
          </template>
        </pl-table-column>
        <pl-table-column label="备注" width="150" prop="备注"></pl-table-column>
        <pl-table-column label="修改" width="100">
          <template slot-scope="scope">
            <el-button plain size="mini" type="primary" @click="ExLogEdit(scope.row)">修改</el-button>
          </template>
        </pl-table-column>
      </pl-table>
      <el-pagination
        style="margin-top:1rem"
        @current-change="PageChange"
        :current-page="page+1"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </div>
    <!-- 修改层 -->
    <el-dialog
      title="原辅料出库日志修改"
      :visible.sync="修改层"
      fullscreen
    >
      <el-form>
        <el-form-item label="【分类】" label-width="140px">{{ 修改项.分类 }}</el-form-item>
        <el-form-item label="【品名】" label-width="140px">{{ 修改项.品名 }}</el-form-item>
        <el-form-item label="【单位】" label-width="140px">{{ 修改项.单位 }}</el-form-item>
        <el-form-item label="【规格】" label-width="140px">
          <span v-html="FormatGG(修改项.规格)"></span>
        </el-form-item>
        <el-form-item label="【供应商】" label-width="140px">{{ 修改项.供应商 }}</el-form-item>
        <el-form-item label="【领用部门】" label-width="140px">
          <el-input
            v-model="修改项.领用部门"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【领用人】" label-width="140px">
          <el-input
            v-model="修改项.领用人"
            style="width:300px"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="【领用日期】" label-width="140px">
          <el-date-picker v-model="修改项.日期" size="mini"></el-date-picker>
        </el-form-item>
        <el-form-item label="【领用数量】" label-width="140px">
          <el-input size="mini" v-model="修改项.数量" style="width:160px"></el-input>
        </el-form-item>
        <el-form-item label="【备注】" label-width="140px">
          <el-input
            v-model="修改项.备注"
            style="width:500px"
            :rows="3"
            size="mini"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="padding:10px 0 0 140px">
        <el-button @click="修改层=false">取 消</el-button>
        <el-button type="danger" @click="DoEdit">修改出库日志</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {baseTitle, baseUrl} from '../configs/squareConfig'
import moment from "moment";
import {getRawOutLogs, rawOutLogEdit} from "../http/api";
import {formRequest} from "../http/formRequest";

export default {
  name: "RawExLogs",
  data() {
    return {
      form: {},
      tableData: [],
      page: 0,
      pageSize: 100,
      count: 0,
      token: this.$cookies.get('token'),
      修改层: false,
      修改项: {},
    }
  },
  methods: {
    GetRawOutLogs() {
      let form = this.form
      form.page = this.page
      getRawOutLogs({
        token: this.token,
        form,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.tableData = res.data.rs
            this.page = res.data.page
            this.count = res.data.count
            this.pageSize = res.data.pageSize
          }
        })
        .catch(err => console.log(err))
    },
    OutPut() {
      let url = baseUrl + '/raw-out-logs-output'
      formRequest(url, {
        token: this.token,
        form: JSON.stringify(this.form),
      })
    },
    PageChange(e) {
      this.page = e - 1
      this.GetRawOutLogs()
    },
    ExLogEdit(row) {
      this.修改项 = row
      this.修改层 = true
    },
    DoEdit() {
      rawOutLogEdit({
        token: this.token,
        form: this.修改项,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.修改项.操作人 = res.data.user
            this.修改层 = false
            this.$message({
              type: "success",
              message: '出库日志修改成功'
            })
          }
        })
        .catch(err => console.log(err))
    },
  },
  computed: {
    FormatGG() {
      return gg => {
        if (!gg) return ''
        gg = JSON.parse(gg)
        let arr = []
        for (let key in gg) {
          arr.push(`${key}：${gg[key]}`)
        }
        return arr.join('<br>')
      }
    },
    FormatDate() {
      return date => moment(new Date(date)).format('YYYY/MM/DD')
    },
    FormatTime() {
      return time => moment(new Date(time)).format('YYYY/MM/DD HH:mm:ss')
    },
    GetTdWidth() {
      return item => {
        if (item === '原料id') return 80
        if (item === '分类') return 130
        if (item === '供应商') return 140
        if (item === '品名') return 120
        if (item === '单位') return 80
        if (item === '数量') return 100
        if (item === '领用单位') return 100
        if (item === '领用人') return 100
        if (item === '联系方式') return 140
        if (item === '操作人') return 120
      }
    }
  },
  activated() {
    document.title = '原料出库日志 - ' + baseTitle
    this.GetRawOutLogs()
  }
}
</script>

<style scoped>

</style>
